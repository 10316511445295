@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/poppins/Poppins-Medium.ttf");

}

@font-face {
    font-family: "Inter";
    src: url("../public/fonts/inter/Inter-Medium.ttf");

}

@layer components {
    .btn-primary {
        @apply inline-block font-serif px-6 py-2 bg-light-blue text-white font-medium prose-base rounded shadow-md hover:bg-dark-blue hover:shadow-lg flex items-center justify-center gap-3 transition duration-150 ease-in-out
    }

    .btn-primary-small {
        @apply py-[5px] font-serif px-[10px] text-white bg-dark-blue dark:bg-light-blue dark:text-gray rounded font-medium flex justify-center items-center gap-[15px]
    }

    .special-button {
        @apply inline-block font-serif min-w-[100px] font-medium py-2.5 px-1 border-2 border-light-blue text-light-blue prose-base rounded hover:bg-light-blue hover:text-white hover:border-white text-center transition duration-150 ease-in-out
    }

    .feature-card {
        @apply flex justify-center font-serif flex-col max-w-[590px] h-[325px] md:min-h-[500px] md:p-[40px] border-collapse dark:border-white border-light-blue dark:text-gray-dark text-dark-black
    }

    .sidebar-option {
        @apply flex items-center font-serif w-full h-12 px-1 mt-2 rounded hover:bg-gray-700 hover:text-gray-300
    }

    .sidebar-option--active {
        @apply flex items-center font-serif w-full h-12 px-1 mt-2 text-gray-200 bg-gray-700 rounded
    }

    p {
        @apply text-dark-black dark:text-white prose-base font-medium font-serif
    }

    .gradient {
        background: linear-gradient(90deg, #ff9fe1 0, #8727ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .icon {
        font-size: 25px;
        fill: white;
        opacity: 75%;
    }

    .control-body .hr {
        border: 1px solid rgba(255, 255, 255, 0.05) !important;
    }

    .control-body p {
        opacity: 80%;
        margin-bottom: 10px;
        font-size: 1rem;
    }

    .control-body section {
        margin: 35px 0;
    }

}
